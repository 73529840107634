.parallax {
  background-image: url(../../../assets/img/about_bg.png);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .parallax {
    background-image: none;
  }
}

.about-section .content {
  text-align: left;
}

.about-section .content .section-header {
  text-align: left;
  margin-bottom: 50px;
}

.about-section .img {
  text-align: center;
  padding: 0px 30px;
  position: relative;
}

.about-section .img img {
  width: 100%;
}

.about-section .img .bg-shape {
  position: absolute;
  top: -3%;
  left: -3%;
  z-index: -1;
  animation: scaleAni 5s linear infinite;
}

@media (max-width: 1199px) {
  .about-section .content img {
    max-width: 150px;
  }
}

@media (max-width: 991px) {
  .about-section .img {
    padding: 60px 30px 0px;
  }

  .about-section .img .bg-shape {
    top: 5%;
  }
}

@media (max-width: 575px) {
  .about-section .img .bg-shape {
    animation: none;
  }
}

@media (max-width:1250px) {
  .about-phn-view {
    transform: translateY(-50px);
  }
}

@media (max-width:600px) {
  .about-phn-view {
    transform: translateY(-70px);
  }
  .about-section .img {
    padding: 0px 40px 0px;
  }

  .about-section .img .bg-shape {
    transform: translatey(-44px);
  }
}