.logo {
  width: auto;
  height: 100px;
  transform: translateY(-10px);
}

.onliner-order-desktop {
  background: var(--color-theme) !important;
  color: #fff !important;
  padding: 8px 15px;
  font-size: 0.9rem;
  border-radius: 50px;
}

.logo-size {
  height: 150px;
  padding: 10px;
  border-radius: 50% !important;
  transform: translateY(-17px);
}

.halal-logo-size {
  height: 80px;
  padding: 15px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
  transform: translateY(-17px);
  background: #fff;
  border: 1px solid #5fc400 !important;
  border-top-color: rgb(95, 196, 0) !important;
  border-right-color: rgb(95, 196, 0) !important;
  border-bottom-color: rgb(95, 196, 0) !important;
  border-left-color: unset !important
}

/* ************* Halal Icon ************** */
.halal-img-box {
  position: fixed;
  top: 62%;
  left: -1px;
  z-index: 9;
}

.fixed-top.top-bar {
  animation: navbackground 1s ease-out;
  background: #fff;
}

.fixed-top.top-bar .nav-link {
  color: #000 !important;
}

.fixed-top.top-bar .logo-size {
  background: #fff;
  animation: navlogobackground 1s ease-out;
  /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
  /* filter: drop-shadow(0 1rem 0.75rem rgba(0,0,0,.075)); */
}

@keyframes navbackground {
  0% {
    background: #ffffff7c;
    transform: translateY(-100px);
  }

  100% {
    background: #ffffff;
    transform: translateY(0px);
  }
}

@keyframes navlogobackground {
  0% {
    background: #ffffff7c;
  }

  100% {
    background: #ffffff;
  }
}

.pc-sm-icon {
  list-style: none;
}

.pc-sm-icon li a {
  width: 40px;
  height: 40px;
  color: var(--theme-color-white) !important;
  background: var(--color-theme);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.pc-sm-icon li:last-child a {
  margin-right: 0;
}

.phn-online-btn {
  background-color: var(--color-theme);
  color: #fff !important;
  border-color: var(--color-theme) !important;
}

.order-online-btn.fixed-bottom {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
}


@media (max-width: 1099px) {
  .gap-nav-list {
    gap: 0.6rem !important;
  }
}

/* @media (max-width: 992px) { */
@media (max-width: 1200px) {
  .toggle-position-phnview {
    transform: translateY(30px);
  }

  .nav-brand-size {
    right: 0;
    transform: translateY(-33px);
  }

  .navbar-link-color a {
    color: #000 !important;
  }

  .navbar-link-color {
    padding-left: 10px;
    font-size: 23px;
    line-height: 0.8rem;
  }

  .toggle-position-phnview .navbar-toggler {
    transform: translateY(-30px) !important;
  }

  .logo-size {
    height: 130px;
    padding: 0px;
    border-radius: 50%;
    transform: translateY(-17px);
  }

  /* .mobile-review-box .react-stars span{
    color: gray !important;
  } */
}

/* @media (min-width: 992px) { */
@media (min-width: 1200px) {
  .navbar-link-color a {
    color: #fff !important;
  }

  .navbar-link-color {
    align-items: center;
  }
}

/* phoneview cross */
.phn-view-sidebar>.btn-close {
  transform: translate(-20px, -20px);
  font-size: 25px;
}

.toast-header .btn-close {
  margin: 0 !important;
}

.mobile-review-box {
  display: none;
}

.position-fixed.bottom-0 {
  z-index: 9999 !important;
}

@media (max-width: 992px) and (min-width: 568px) {
  .recipe-img-wrapper-tab-view {
    width: 100%;
  }

  .recipe-img-wrapper-tab-view img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 1rem;
  }

  .food-type-filter-row {
    padding-bottom: 1rem !important;
  }

  .item-category-filter {
    max-width: fit-content !important;
  }

  .recent-order-box .card-text {
    overflow-x: auto;
  }

  .order-list-view-main {
    width: 200%;
  }

  /* .order-list-view-box, */
  /* .order-list-view-box-no{
      max-width: fit-content !important;
    } */
}

/* @media (max-width: 992px) { */
@media (max-width: 1200px) {
  .mobile-sm-icon {
    display: flex;
    list-style: none;
    padding-left: 0;
  }

  .mobile-sm-icon a {
    color: #000;
    margin-right: 1rem;
    font-size: 1.5rem;
  }

  .mobile-call-icon {
    color: #000;
  }

  .mobile-call-icon svg {
    font-size: 1.3rem;
    margin-right: 7px;
    margin-top: 0;
  }

  .mobile-review-box .btn-grub {
    display: flex;
    flex-direction: column;
    color: var(--color-theme) !important;
    background: transparent !important;
    text-align: center;
  }

  .mobile-review-box p {
    text-align: center;
    font-weight: bold;
    font-size: 0.9rem;
    color: var(--color-theme) !important;
  }
}

@media (max-width: 500px) {
  .phn-view-sidebar {
    max-width: 70% !important;
  }

  .navbar-link-color {
    font-size: 1rem;
  }

  .masla-of-india-home .toggle-position-phnview .navbar-toggler {
    transform: translateY(20px) !important;
  }

  .fixed-top.top-bar .navbar-toggler {
    transform: translateY(0) !important;
    /* position: static !important; */
  }

  .logo-size {
    width: 90px;
    height: 90px;
    padding: 0px;
    border-radius: 50%;
    object-fit: contain;
    transform: translateY(-11px);
  }

  .toggle-position-phnview,
  .nav-brand-size,
  .toggle-position-phnview .navbar-toggler {
    transform: translateY(0) !important;
  }

  .nav-brand-size .logo {
    transform: translateY(-15px);
  }

  .navbar-link-color .onliner-order-mobile {
    color: #fff !important;
  }

  .mobile-nav-heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-theme);
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .mobile-nav-heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-theme);
  }
}

@media (max-width: 360px) {
  .phn-view-sidebar {
    max-width: 80% !important;
  }
}

@media (max-width: 310px) {
  .phn-view-sidebar {
    max-width: 90% !important;
  }
}

.onliner-order-mobile {
  border-radius: 8px !important;
}

@media (min-width: 501px) and (max-width: 1200px) {
  .btn-tab-font {
    font-size: 23px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1360px) {
  .gap-nav-list {
    gap: 0.2rem !important;
  }
}

@media (min-width: 1360px) {
  .gap-nav-list {
    gap: 1.5rem !important;
  }
}

@media (min-width:600px) and (max-width:1200px) {

  .tab-view-item,
  .logo-tab {
    display: flex;
    color: var(--theme-color-white);
    margin-right: 20px;
    font-size: 17px;
  }

  .tab-view-item {
    background: var(--color-theme) !important;
    height: 39px !important;
    padding: 0 15px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 10px !important;
  }
}

@media (max-width:680px) and (min-width:600px) {

  .tab-view-item,
  .logo-tab {
    display: flex;
    color: var(--theme-color-white);
    margin-right: 20px;
    font-size: 13px !important;
  }

  .tab-view-item {
    background: var(--color-theme) !important;
    height: 39px !important;
    padding: 0 15px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 10px !important;
  }
}

@media (min-width:1200px) {
  .tab-view-item {
    display: none;
  }
}

@media (max-width:600.9px) {
  .tab-view-item {
    display: none;
  }
}