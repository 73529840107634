.catering-img {
  width: 400px;
  object-fit: contain;
  border-radius: 22px;
}
.book-table-section {
  /* padding: 130px 0px 130px; */
  position: relative;
}

.book-table-section .section-header {
  margin-bottom: 52px;
}

.book-table-section .book-table-box {
  padding: 60px 100px 60px;
  background: #fff;
  border-radius: 22px;
}

.book-table-section .book-table-box .number {
  display: block;
  text-align: center;
  margin-bottom: 35px;
  font-size: 60px;
  line-height: 70px;
  font-weight: 300;
  color: #181919;
}

.book-table-section .book-table-box .number img {
  margin-right: 5px;
}

.book-table-section .book-table-box .opening-time {
  display: flex;
}

.book-table-section .book-table-box .opening-time .left {
  max-width: 50%;
  flex: 50%;
  color: #fff;
  text-align: center;
  position: relative;
}

.book-table-section .book-table-box .opening-time .left::before {
  position: absolute;
  content: "|";
  color: #181919;
  right: 0px;
  top: 0px;
}

.book-table-section .book-table-box .opening-time .right {
  max-width: 50%;
  flex: 50%;
  color: #fff;
  text-align: center;
}

.book-table-section .book-table-box .title-area {
  margin-top: 56px;
  margin-bottom: 56px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
}

.book-table-section .book-table-box .title-area .table-title {
  display: inline-block;
  /* color: #181919; */
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  margin: 0px 30px;
  margin-bottom: 0px;
}

.book-table-section .book-table-box .book-form input,
.book-table-section .book-table-box .book-form select {
  height: 60px;
  border: 1px solid #fff;
  border-radius: 5;
  padding: 0px 30px;
  /* margin-bottom: 30px; */
  position: relative;
  background: #f3f3f3;
  font-size: 1.25rem;
}
.home-catering-form .book-form input,
.home-catering-form .book-form select {
  /* margin-bottom: 30px; */
}
.home-catering-form .book-form .ant-picker {
  border: none !important;
  padding: 0 !important;
  /* margin-bottom: 30px; */
}
.home-catering-form .book-form .ant-picker .ant-picker-input {
  position: relative;
}
.home-catering-form .book-form .ant-picker .ant-picker-input input {
  border-radius: 0.375rem !important;
  margin-bottom: 0 !important;
}
.home-catering-form
  .book-form
  .ant-picker
  .ant-picker-input
  .ant-picker-suffix {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
.home-catering-form .ant-picker-outlined:focus-within,
.home-catering-form .ant-picker-outlined:focus {
  box-shadow: none;
}
.book-table-section
  .book-table-box
  .book-form
  input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

.book-table-section
  .book-table-box
  .book-form
  input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

.book-table-section .book-table-box .book-form .custom-button {
  font-size: 20px;
  line-height: 30px;
  margin-top: 30px;
  background: #36034a !important;
  color: #fff;
  padding: 10px 70px;
  border-radius: 50px;
}

.book-table-section .book-table-box .book-form .custom-button:hover {
  background: #4b9438;
  color: #fff !important;
}

.book-table-section .book-table-box .book-form .input-group {
  display: block;
  width: 100%;
}

.book-table-section .book-table-box .book-form .input-group .single-input {
  display: inline-block;
}

.book-table-section .book-table-box .book-form .input-group .single-input.one {
  width: 55%;
  position: relative;
}

.book-table-section
  .book-table-box
  .book-form
  .input-group
  .single-input.one
  img {
  position: absolute;
  top: 17%;
  left: 30px;
}

.book-table-section
  .book-table-box
  .book-form
  .input-group
  .single-input.one
  input {
  border-radius: 50px 0px 0px 50px;
  padding: 0px 30px 0px 75px;
}

.book-table-section .book-table-box .book-form .input-group .single-input.two {
  width: 45%;
  margin-left: -6px;
  position: relative;
}

.book-table-section
  .book-table-box
  .book-form
  .input-group
  .single-input.two
  img {
  position: absolute;
  top: 17%;
  left: 30px;
}

.book-table-section
  .book-table-box
  .book-form
  .input-group
  .single-input.two
  input {
  border-radius: 0px 50px 50px 0px;
  padding: 0px 30px 0px 75px;
}

@media (max-width: 991px) {
  .book-table-section .book-table-box {
    padding: 60px 30px 60px;
  }

  .book-table-section .shape1,
  .book-table-section .shape2 {
    display: none;
  }

  .book-table-section .book-table-box .number {
    font-size: 30px;
    line-height: 40px;
  }

  .book-table-section .book-table-box .number img {
    width: 40px;
  }

  .book-table-section .book-table-box .opening-time {
    display: block;
    text-align: center;
  }

  .book-table-section .book-table-box .opening-time .left::before {
    display: none;
  }

  .book-table-section .book-table-box .opening-time .left,
  .book-table-section .book-table-box .opening-time .right {
    max-width: 100%;
    flex: 100%;
  }

  .book-table-section .book-table-box .title-area .table-title {
    font-size: 30px;
    line-height: 40px;
    margin: 0px 10px;
  }

  .book-table-section .book-table-box .title-area img {
    width: 55px;
  }
}

@media (max-width: 575px) {
  .book-table-section .book-table-box .book-form .custom-button{
    font-size: 16px;
  }
  .book-table-section .book-table-box .book-form .input-group .single-input.two,
  .book-table-section
    .book-table-box
    .book-form
    .input-group
    .single-input.one {
    width: 100%;
    margin-left: 0px;
  }

  .book-table-section
    .book-table-box
    .book-form
    .input-group
    .single-input.two
    input,
  .book-table-section
    .book-table-box
    .book-form
    .input-group
    .single-input.one
    input {
    border-radius: 50px;
  }
}

.catering-textarea {
  height: 238px;
  background: #f3f3f3;
  padding: 15px 30px;
}

.form-control:focus {
  background: #f3f3f3 !important;
}

.catering-label {
  color: #181919 !important;
}

@media (max-width: 600px) {
  .book-table-section .book-table-box {
    padding: 30px 15px;
    background: #fff;
    border-radius: 22px;
  }
  .book-table-section .book-table-box .book-form input,
  .book-table-section .book-table-box .book-form select {
    height: 60px;
    border: 1px solid #fff;
    border-radius: 5;
    padding: 0px 15px;
    /* margin-bottom: 30px; */
    position: relative;
    background: #f3f3f3;
    font-size: 20px;
  }
  .catering-textarea {
    height: 150px;
    background: #f3f3f3;
    padding: 15px !important;
  }
}

.datepicker-size .ant-picker {
  min-height: 60px !important;
  height: 60px !important;
  background: #f3f3f3;
  padding-left: 1.8rem !important;
  /* padding-top: 0 !important; */
  border: none !important;
}
.datepicker-size .ant-picker .ant-picker-input,
.datepicker-size .ant-picker input {
  height: 100% !important;
  border: none !important;
}
.datepicker-size .ant-picker input::placeholder {
  color: #323639;
}
.datepicker-size .ant-picker-input input {
  height: 60px;
  border: none !important;
  padding: 0 !important;
}
@media (max-width: 568px) {
  .catering-img {
    width: 100%;
  }
  .datepicker-size .ant-picker {
    padding-left: 1rem !important;
  }
}

/* Catering Menu Pdf */
.catering-menu {
  font-size: 20px;
  line-height: 30px;
  margin-top: 30px;
  background: #36034a !important;
  color: #fff;
  padding: 10px 25px;
  border-radius: 50px;
  border: none !important;
}
@media (max-width: 365px) {
  .catering-menu {
    font-size: 16px;
    line-height: 30px;
    margin-top: 30px;
    background: #36034a !important;
    color: #fff;
    padding: 10px 25px;
    border-radius: 50px;
    border: none !important;
  }
}
