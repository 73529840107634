.testimonial-section .react-multi-carousel-dot button {
  border-color: #fff !important;
}
.testimonial-section .react-multi-carousel-dot--active button {
  background: #fff !important;
}
.delivery-section .testimonial-slid img {
  height: 100px;
  object-fit: cover;
}
.testimonial-slid p {
  text-align: center !important;
  font-size: 1.1rem;
}
@media (min-width: 992px) {
  .testimonial-slid p {
    font-size: 1.8rem;
  }
  .testimonial-slid span {
    font-size: 1.3rem;
  }
  .delivery-section .testimonial-slid{
    margin-right: 1rem;
  }
}
@media (max-width: 568px) {
  .delivery-section .testimonial-slid{
    margin: 0 10px;
  }
  .delivery-section .testimonial-slid img{
    padding: 5px;
  }
}
