@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  /* --color-theme: #d70000; */
  --color-theme: #36034A;
  --color-white: #ffffff;
  --theme-color-dark: #333333;
  --linkedin-blue: #0072b1;
  --theme-color-white: #ffffff;
  --theme-highlight-text: #20395c;
  --theme-bg-light: #eeeeee;
  --theme-text-black: #000000;
  --theme-color-veg: #178138;
  --theme-color-nonveg: #bc0612;
  --theme-color-vegan: #008000;
  /* --color-theme: #36034A; */
  /* --btn-hover-color: #fec100; */
  --btn-hover-color: #eabf7e;
  --body-bg-color: #f3f3f3;
}

html,
body {
  font-family: "Poppins", sans-serif;
  color: var(--theme-color-dark);
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
  background: var(--body-bg-color);
}

/* 
h1 {
  font-size: 90px;
  line-height: 100px;
}

h2 {
  color: #36034A !important;
  display: block;
  font-size: 35px;
  line-height: 10px;
  margin-bottom: 13px;
  text-transform: uppercase;
  font-family: 'Allura', cursive !important;
  letter-spacing: 6px;
  font-weight: 800 !important;
}

h3 {
  font-size: 50px !important;
  line-height: 70px;
  font-weight: 700;
  font-family: "/", sans-serif !important;
  color: #000 !important;
} */

/* p {
  font-size: 1.1rem ;
  color: #181919 ;
  line-height: 2rem ;
  font-family: "Josefin Sans", sans-serif;
  text-align: justify;
} */

a {
  color: var(--theme-color-dark);
  text-decoration: none !important;
}

.btn {
  padding: 0.8rem 1rem !important;
  border-color: var(--color-theme) !important;
}

.btn.btn-sm {
  padding: 0.25rem !important;
}

.btn:hover {
  background-color: var(--btn-hover-color) !important;
  border-color: var(--btn-hover-color) !important;
}

.btnicons {
  width: 20px;
}

.swiper-slide {
  height: auto !important;
}

.btn-close {
  filter: invert(0);
  opacity: 1 !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--color-theme) !important;
  opacity: .6 !important;
}

/* Disable zooming on Safari */
@media (max-width: 767px) {

  html,
  body {
    touch-action: manipulation !important;
  }
}

.main-route-box {
  touch-action: manipulation !important;
}