.static-page {
  padding-top: 2rem;
  min-height: 60vh;
}
.static-page svg {
  display: block;
  font-size: 3rem;
  color: #000;
  margin: 0 auto;
}
.static-page ul {
  list-style: none;
  padding: 0;
}
.static-page ul li {
    margin-bottom: 1rem;
    padding-left: 15px;
}
.static-page ul b{
    margin-bottom: 10px;
    display: block;
}
