.offer-modal .ant-modal-content {
    padding: 0;
    /* padding-bottom: .8rem; */
    border-radius: 22px !important;
    overflow: hidden;
    background: var(--color-theme); 
  /* background: transparent; 
  box-shadow: none; */
}
.offer-modal .ant-modal-footer{
    display: none;
}
.offer-modal .ant-modal-content .review-btn {
  /* border-radius: 0 0 22px 22px; */
  margin-top: auto;
  font-size: 1.2rem;
  padding: .9rem 2rem;
  background: var(--theme-color-white) !important;
  color: var(--color-theme) !important;
  font-weight: 700;
}
.offer-modal .ant-modal-close {
  color: var(--color-theme);
  background: var(--theme-color-white) !important;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  border-radius: 0;
  border-bottom-left-radius: 22px;
  font-weight: bold;
}

.offer-modal .ant-modal-close svg{
    font-size: 1rem !important;
}