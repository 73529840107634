@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");


@import url('https://fonts.googleapis.com/css2?family=Cardo:ital@1&family=Roboto+Slab:wght@300&family=Spectral:ital,wght@1,300&display=swap');

/* :root {
  --color-theme: #eb1c24;
  --color-white: #ffffff;
  --theme-color-dark: #333333;
  --linkedin-blue: #0072b1;
  --theme-color-white: #ffffff;
  --theme-highlight-text: #20395c;
  --theme-bg-light: #eeeeee;
  --theme-text-black: #000000;
  --theme-color-veg: #178138;
  --theme-color-nonveg: #bc0612;
  --theme-color-vegan: #008000;
  --color-theme: #36034A;
} */

.masla-of-india-home {
  background: #f3f3f3 !important;
  height: 100%;
}

h1 {
  font-size: 90px;
  line-height: 100px;
  /* font-family: "Josefin Sans"; */
  font-family: 'Roboto Slab', serif;
}

h2 {
  color: var(--color-theme);
  display: block;
  font-size: 35px;
  line-height: 10px;
  margin-bottom: 13px;
  text-transform: capitalize;
  font-family: 'Roboto Slab', serif;
  letter-spacing: 6px;
  font-weight: 800;
}

h3 {
  font-size: 50px;
  line-height: 70px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif !important;
  color: #000 !important;
}

h4 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  color: var(--color-theme) !important;
}

.masala-common-content {
  font-size: 1.1rem !important;
  color: #181919 !important;
  line-height: 2rem !important;
  font-family: "Josefin Sans", sans-serif;
  text-align: justify;
}

.section-padding {
  padding: 0px 0px 40px !important;
}

@media (max-width:1200px) {
h2{
  line-height: inherit;
}
}
@media (max-width:900.9px) {
  h2 {
    color: var(--color-theme);
    display: block;
    font-size: 30px;
    line-height: in;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;
    letter-spacing: 2px;
    font-weight: 800;
  }

  h3 {
    font-size: 35px;
    line-height: 3rem;
    font-weight: 700;
    font-family: "Montserrat", sans-serif !important;
    color: #000 !important;
  }
}

@media (max-width:630px) {
  h2 {
    color: var(--color-theme);
    display: block;
    font-size: 25px;
    line-height: unset !important;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;
    letter-spacing: 2px;
    font-weight: 800;
  }

  h3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif !important;
    color: #000 !important;
  }
}


@media (max-width:400px) {
  h2 {
    color: var(--color-theme);
    display: block;
    font-size: 20px;
    line-height: unset !important;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;
    letter-spacing: 2px;
    font-weight: 800;
  }

  h3 {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif !important;
    color: #000 !important;
  }
}


@media (max-width:1200px) {
  .masala-common-content {
    /* font-size: 1.1rem !important; */
    color: #181919 !important;
    line-height: 2rem !important;
    font-family: "Josefin Sans", sans-serif;
    text-align: justify;
  }

  h4 {
    font-weight: 400;
    font-size: 1.3em;
    line-height: 1.334;
    color: var(--color-theme) !important;
  }
}