.common-home-banner .react-multi-carousel-list,
.common-home-banner .react-multi-carousel-track {
  height: 100vh;
}

.home-banner-slide {
  position: relative;
}

.home-banner-slide-content {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -40%);
  color: #fff;
  width: 100%;
  padding: 0 2.5rem;
  text-align: center;
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
}

.carousel-control-next,
.carousel-control-prev {
  width: 2.5%;
}

@media screen and (max-width: 768px) {
  .carousel-item img {
    height: 60vh;
    object-fit: none;
  }

  .carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    padding: 50px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 15%;
  }
}

@media (min-width: 992px) {
  .home-banner-slide-content {
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    width: fit-content;
    padding: 0;
  }

  .home-banner-slide-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }  
}

.home-banner-slide::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.527);
}

.common-home-banner .react-multi-carousel-dot-list {
  bottom: 50px !important;
}

.common-home-banner .react-multiple-carousel__arrow {
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid #fff;
}

.common-home-banner .react-multiple-carousel__arrow::before {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

.common-home-banner .react-multi-carousel-dot-list {
  display: none !important;
}

/* Theame Banner */
.banner-section {
  top: -120px;
  padding: 230px 0px 440px;
  position: relative;
  overflow: hidden;
  background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.6)),
    url(../../../pages/Masala-of-india/assets/images/banner/banner-bg-image.png);
}

@media (max-width:1530px) {
  .banner-section {
    top: -147px;
    padding: 230px 0px 440px;
    position: relative;
    overflow: hidden;
    background-image:
      linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.6)),
      url(../../../pages/Masala-of-india/assets/images/banner/banner-bg-image.png);
  }
}

.banner-section .img1 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 2;
}

.banner-section .img2 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 2;
}

.banner-section .img3 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  width: 100%;
}

.banner-section .main-content {
  text-align: center;
  position: relative;
  z-index: 5;
}

@media (min-width: 490px) {
  .banner-section .main-content .main-sub-title {
    font-size: 36px !important;
    line-height: 46px !important;
    font-weight: 600 !important;
    color: #fff !important;
    margin-bottom: 25px !important;
  }
}

.banner-section .main-content .main-title {
  font-size: 110px;
  line-height: 120px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 22px;
}

@media (max-width: 1600px) {
  .banner-section .img1 {
    width: 420px;
  }

  .banner-section .img2 {
    width: 420px;
  }

  .banner-section {
    padding: 278px 0px 200px;
  }

  .banner-section .main-content .main-title {
    font-size: 70px;
    line-height: 100px;
  }

  .menu-banner-section .info-box {
    left: 30px;
  }

  .menu-banner-section .infoimage {
    left: -40px;
  }
}

/* @media (max-width: 1199px) { */
@media (max-width: 1280px) {

  .banner-section .main-content .main-title {
    font-size: 60px;
    line-height: 70px;
  }

  .banner-section {
    padding: 277px 0px 200px;
  }

  .banner-section .img1 {
    width: 320px;
  }

  .banner-section .img2 {
    width: 300px;
  }

  .menu-banner-section .info-box,
  .menu-banner-section .infoimage {
    display: none;
  }

  .menu-banner-section {
    padding: 133px 0px 112px;
  }
}

@media (max-width: 991px) {
  .banner-section .main-content .main-title {
    font-size: 50px;
    line-height: 60px;
  }


  .banner-section {
    padding: 220px 0px 150px;
  }

  .menu-banner-section .main-comtent .left-area .title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
  .menu-section .menu-tab-area .shape-2 {
    display: none;
  }

  .menu-banner-section .main-comtent {
    display: block;
  }

  .menu-banner-section .main-comtent .right-area .custom-button {
    margin-top: 26px;
  }
}

@media (max-width: 575px) {
  .about-section .img .bg-shape {
    animation: none;
  }

  .banner-section .main-content .main-title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 320px) {

  .banner-section .main-content .main-title {
    font-size: 34px;
    line-height: 44px;
  }
}

@media (max-width:860px) {

  .banner-section .img1,
  .banner-section .img2 {
    width: 240px;
  }
}

@media (max-width:490px) and (min-width:424px) {

  .banner-section .img1,
  .banner-section .img2 {
    width: 210px;
  }
}

@media (max-width:424px) and (min-width:360px) {

  .banner-section .img1,
  .banner-section .img2 {
    width: 180px;
  }
}

@media (max-width:360px) {

  .banner-section .img1,
  .banner-section .img2 {
    width: 150px;
  }
}

@media (max-width:300px) {

  .banner-section .img2,
  .banner-section .img1 {
    display: none;
  }
}

@media (max-width:490px) {
  .banner-section .main-content .main-title {
    font-size: 30px;
    line-height: 46px;
  }

  .banner-section .main-content .main-sub-title {
    font-size: 20px !important;
    line-height: 46px !important;
    font-weight: 600 !important;
    color: #fff !important;
    margin-bottom: 0 !important;
  }
}

@media (max-width:415px) {
  .banner-section .main-content .main-title {
    /* font-size: 24px; */
    line-height: 46px;
  }
}
/* 
@media (max-width:992px) and (min-width:900px){
  .banner-section {
    top: -140px;
    padding: 230px 0px 440px;
    position: relative;
    overflow: hidden;
 background-image:
      linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.6)),
      url(../../../pages/Masala-of-india/assets/images/banner/banner-bg-image.png);
  }
}*/
/* @media (max-width:900px){
  .banner-section {
    top: -110px;
    padding: 230px 0px 440px;
    position: relative;
    overflow: hidden;
 background-image:
      linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.6)),
      url(../../../pages/Masala-of-india/assets/images/banner/banner-bg-image.png);
  }
}  */