.poweredby {
  text-decoration: none;
  color: var(--theme-color-dark);
  font-weight: 600;
  font-size: 15px;
}

footer {
  /* border-bottom: 10px solid var(--color-theme) !important; */
  height: auto;
}

.payment-icons li {
  list-style: none;
  display: inline-block;
  margin-right: 5px;
}

.payment-icons svg {
  height: 40px;
  width: 50px;

}

.powered-by {
  width: 65px;
}

.footer-nav {
  list-style-type: none;
  display: flex;
  gap: 20px;
  padding-left: 0;
  margin-bottom: 0;
}

.footer-nav li a {
  color: var(--theme-color-white);
  text-decoration: none;
  transition: all ease-in-out .3s;
}

/* .footer-nav li a:hover {
  color: var(--color-theme);
  text-decoration: none;
} */

.fssai-logo {
  width: 65px;
  height: auto;
}

@media screen and (max-width: 768px) {
  footer {
    height: auto;
    border-bottom: 0px solid var(--color-theme);
  }

  .footer-nav li a {
    color: var(--theme-color-white) !important;
  }

  .footer-nav {
    list-style-type: none;
    display: flex;
    gap: 10px;
    padding-left: 0;
    margin-bottom: 0;
    flex-direction: column;
    text-align: left;
    /* margin-left: -10px; */
    margin-top: 20px;
  }
}

