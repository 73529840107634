/* Testimonial Section Starts Here*/
  .testimonial-section .section-header {
    text-align: left;
    margin-bottom: 0px;
  }
  .testimonial-section .section-header .custom-button {
    margin-top: 41px;
    font-size: 16px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    background: #4b9438;
    padding: 12px 30px 10px;
  }
  .testimonial-section .section-header .custom-button:hover {
    color: #fff !important;
  }
  .testimonial-section .section-header .custom-button img {
    margin-left: 5px;
    position: relative;
    top: -3px;
  }
  .testimonial-section .testimonial-slider {
    position: relative;
  }
  .testimonial-section .testimonial-slider{
    height: 100%;
    background: #ffffff84;
    border-radius: 22px;
    padding: 50px;
    text-align: center !important;
  }
 
.testimonial-slider .slick-slider .slick-list .slick-track{
    align-items: center;
    display: flex;
}
.testimonial-slider div p{
    text-align: center !important;
}

.slick-next {
    right: 40% !important;
    /* background: #36034A !important; */
    /* border-radius: 50px; */
    height: 40px !important;
    width: 40px !important;
    top: 100% !important;
    padding-top: 10px !important;
}
.slick-prev{
    /* background: #36034A !important; */
    /* border-radius: 50px !important; */
    height: 40px !important;
    width: 40px !important;
    top: 100% !important;
    left: 40% !important;
    padding-top: 10px !important;
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 30px !important;
    line-height: 1;
    opacity: .75;
    color: #36034A !important;
    display: none !important;
}
.big-circle-img .right-quote, .small-circle-img .left-quote{
  font-size: 100px;
}
.big-circle-img{
  right: -20px;
  top: -50px;
  opacity: 0.1;
}
.small-circle-img{
  left: -30px;
  bottom: -40px;
  opacity: 0.1;  
}
.testimonial-slider-dots .slick-dots li.slick-active button:before {
  opacity: .75;
  color: var(--color-theme);
  font-size: 15px;
}
.testimonial-slider-dots .slick-dots li button:before {
font-size: 10px;
}
.start-color{
  color: #ffde00;
}
.review-btn{
  background: #36034A !important;
  color: #fff !important;
  padding: 15px 20px;
  border-radius: 50px;
}
.review-btn-white{
  background: #fff !important;
  color: #36034A !important;
}

@media (max-width:900px){
  
}

@media (max-width:992px){
  .testimonial-phn-bottom-response{
    margin-bottom: 140px;
  }
}
@media (max-width:785px){
  .big-circle-img {
    right: 50px;
    top: -60px;
    opacity: 0.1;
}
.small-circle-img {
  left: 50px;
  bottom: -60px;
  opacity: 0.1;
}
.testimonial-section .testimonial-slider {
  height: 100%;
  border-radius: 22px;
  padding: 50px 20px;
  text-align: justify !important;
}
.testimonial-section .testimonial-slider .slick-slide{
  margin: 0 10px;
}
.testimonial-slider div p {
  text-align: justify !important;
}
.testimonial-slider div p:nth-child(2) {
  text-align: center !important;
}
.testimonial-phn-bottom-response{
  margin-bottom: 70px;
}
}