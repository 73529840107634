/* .order-type-modal .modal-content {
  width: auto;
  --bs-modal-width: fit-content !important;
} */
.order-type-modal img {
    width: 80%;
    margin-bottom: 10px;
}

.order-type-modal .nav-link {
    display: inline-block;
    font-size: 16px;
    line-height: 1.1em;
    color: var(--color-theme);
    background: transparent;
    text-align: start;
    padding: 10px 20px;
    border: 2px solid var(--color-theme);
    border-radius: 100px;
    z-index: 1;
}

.order-type-modal .ant-checkbox-wrapper {
    font-size: 1rem;
    align-items: center;
}

.order-type-modal .nav-item {
    padding: 0 10px;
    border-left: 2px solid var(--color-theme);
}

.order-type-modal .nav-item:first-child {
    border: none;
}

.order-type-modal .nav-link.active {
    background: var(--color-theme) !important;
    color: var(--theme-color-white);
}

.order-type-modal .flex-column.position-relative {
    width: 50%;
}

.order-type-modal .ant-select-single {
    position: relative;
}

.order-type-modal lable {
    margin-bottom: 5px;
}

.ant-select-dropdown {
    z-index: 9999999 !important;
}