.offer-section {
  cursor: move;
  margin-bottom: 0;
  margin-top: 30px;
}

.offer-icon {
  background-color: var(--color-theme);
  color: var(--theme-color-white);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.offer-section .shadow-sm.card {
  margin: 0 5px;
  cursor: pointer;
  border-left: 8px solid var(--color-theme);
  /* background: transparent;
  box-shadow: none !important;
  padding-left: 3rem; */
}

.coupon-bg {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1; */
  /* opacity: .8; */
}

.coupon-card {
  padding: 10px;
  height: 100%;
}

.coupon-card .offer-icon {
  margin-right: 10px;
}

.coupon-card p {
  margin-bottom: 0;
  font-size: 0.9rem;
}
.offer-coupon-code{
  background: #f3f3f3af;
  padding: 2px 5px;
  border: 1px dashed var(--color-theme);
  border-radius: 5px;
  color: var(--color-theme);
}
@media screen and (max-width: 768px) {
  .offer-section .shadow-sm.card {
    margin: 0 5px;
  }
}

@media screen and (max-width: 568px) {
  .offer-section {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .coupon-card>.d-flex {
    /* position: relative;
    padding-top: 2rem; */
    /* text-align: center;
    flex-direction: column; */
  }

  .coupon-card .offer-icon {
    /* margin: 0; */
    height: 50px;
    width: 50px;
    /* margin-bottom: 10px; */
    /* position: absolute; */
    /* top: -10px;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%); */
  }
}