.service-section {
    padding: 70px 0px 50px;
    background-image:
        linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.6)),
        url(../assets/images/holiday/banner4.png);
    position: relative;
    overflow: hidden;
}

.service-section::after {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 0px;
    height: 150px;
    width: 100%;
    background: #f3f3f3;
}

.service-section .section-header {
    margin-bottom: 40px;
}

.section-header {
    text-align: center;
    margin-bottom: 57px;
}

.section-header.white-color .sub-title {
    color: #fff;
}

.section-header.white-color .title {
    color: #fff;
}

.section-header.white-color .text {
    color: #fff;
}

.section-header .sub-title {
    color: #91999f;
    display: block;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 28px;
    font-weight: 600;
    text-transform: uppercase;
}

.section-header .title {
    font-size: 60px;
    line-height: 70px;
    font-weight: 700;
    margin-bottom: 11px;
    display: block;
}

.section-header .title.extra-padding {
    margin-bottom: 21px;
}

.section-header .text {
    display: inline-block;
}

.service-section .section-header {
    margin-bottom: 40px;
}

.section-header .sub-title {
    font-size: 20px;
    line-height: 30px;
}

.section-header .title {
    font-size: 34px;
    line-height: 44px;
}


.service-box {
    background: #FFF;
    border-radius: 22px;
    overflow: hidden;
    transition: all ease 0.3s;
    position: relative;
    z-index: 3;
    transition: all ease 0.3s;
}

.service-box .img img {
    width: 100%;
    /* height: 416px; */
    /* object-fit: cover; */
}

.service-box .content {
    position: relative;
    padding: 58px 30px 34px;
    text-align: center;
}

.service-box .content .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -15px;
    width: auto;
    border-radius: 50px;
    z-index: 9;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #fff;
    padding: 10px 30px;
    margin-bottom: 0px;
    background: #36034A;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.service-box .content .text {
    margin-bottom: 0px;
}

.service-box .content a {
    margin-top: 28px;
    display: block;
    color: #71b85f;
}

.service-box .content a img {
    margin-left: 7px;
}

.service-box:hover {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
}

@media (max-width: 991px) {
    .service-box .content .title {
        width: 70%;
    }

    .service-box {
        margin-bottom: 30px;
    }
}


.service-box {
    background: #FFF;
    border-radius: 22PX;
    overflow: hidden;
    transition: all ease 0.3s;
    position: relative;
    z-index: 3;
    transition: all ease 0.3s;
    height: 100%;
}

.service-box .img img {
    width: 100%;
}

.service-box .content {
    position: relative;
    padding: 58px 30px 34px;
    text-align: center;
    height: 200px;
}

.service-box .content .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -15px;
    width: auto;
    border-radius: 50px;
    z-index: 9;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #fff;
    padding: 10px 30px;
    margin-bottom: 0px;
    background: #36034A;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.service-box .content .text {
    margin-bottom: 0px;
}

.service-box .content a {
    margin-top: 28px;
    display: block;
    color: #71b85f;
}

.service-box .content a img {
    margin-left: 7px;
}

.service-box:hover {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
}

@media (max-width: 991px) {
    .service-box .content .title {
        width: 70%;
    }

    .service-box {
        margin-bottom: 30px;
    }
}

.holiday-btn {
    background: #36034A;
    border-radius: 50%;
    width: 150px;
    text-align: center;
}

.holiday-menu-btn {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: absolute;
    bottom: 15px;
}

@media (max-width:992px) {
    .holidays-slider-dots .slick-list {
        height: 590px;
    }

    .service-section::after {
        position: absolute;
        content: "";
        bottom: 0px;
        left: 0px;
        height: unset;
        width: 100%;
        background: #f3f3f3;
    }
}

.holidays-slider-dots .slick-track .service-box {
    gap: 20px;
}

@media (max-width:1400px) {
    .service-box .content {
        position: relative;
        padding: 58px 30px 34px;
        text-align: center;
        font-size: 16px;
        height: 265px;
        padding-bottom: 70px;
    }
}

@media (max-width: 900px) {
    .holidays-slider-dots .slick-list {
        height: 100%;
    }
}
@media (max-width: 568px) {
    .service-section{
        padding-bottom: 80px;
    }
}

.holidays-slider-dots .slick-dots li button:before {
    font-family: 'slick';
    font-size: 15px !important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #fff !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}