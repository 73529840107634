.page-header {
  position: relative;
  background: url("../assets/images/inner-banner.jpg") no-repeat center center;
  background-size: cover;
  padding: 170px 0 100px;
  margin-top: -117px;
}

.page-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--theme-color-dark);
  width: 100%;
  opacity: 40%;
}

.page-header .container {
  position: relative;
  z-index: 1;
}

.page-header-box {
  text-align: center;
  color: var(--theme-color-white);
}

.page-header-box h1 {
  font-size: 60px;
}

.page-header-box nav ol {
  justify-content: center;
  color: var(--theme-color-white);
  margin-bottom: 0;
}

.page-header-box nav ol .breadcrumb-item {
  font-size: 16px;
  font-weight: 400;
  color: var(--theme-color-white);
  transition: all 0.3s ease-in-out;
}

.page-header-box nav ol .breadcrumb-item.active {
  font-weight: 600;
  color: var(--theme-color-white);
}

.page-header-box nav ol .breadcrumb-item a {
  color: inherit;
}

.page-header-box nav ol .breadcrumb-item a:hover {
  color: var(--btn-hover-color);
}

.page-header-box nav ol .breadcrumb-item::before {
  color: var(--theme-color-white)
}


@media (min-width:600px) {
  .book-reservation-section .book-table-box {
    padding: 2rem !important;
  }
}

@media (max-width:600px) {
  .book-reservation-section .book-table-box {
    padding: 30px 20px !important;
  }
}

.mother-day-img-radius {
  border-radius: 22px !important;
}