.banner-section-barmenu img {
    height: 30vh;
    border-radius: 10px;
    width: 100%;
    object-fit: cover;

}




.banner-section-barmenu-button {
    display: flex;
    justify-content: center;
    /* gap: 15px;
    margin-top: 10px; */
}

.menu-btn {
    /* background: var(--color-theme) !important; */
    background: transparent;
    color: var(--color-theme) !important;
    border: 2px solid var(--color-theme);
    padding: 8px 15px;
    font-size: 0.9rem;
    border-radius: 50px;
    font-weight: bold;
}

.menu-btn.active {
    background: var(--color-theme) !important;
    color: #fff !important;
    font-weight: normal;
}

.barmenu-sticky-top-category-heading {
    top: 63px !important;
}

.barmenu-sticky-top-category-heading h2 {
    font-size: 1rem !important;
}

@media (max-width:576px) {
    .banner-section-barmenu img {
        height: 25vh;
    }

    .barmenu-item-card-bottom {
        padding-bottom: .5rem !important;
    }
}