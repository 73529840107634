.testimonial-section .react-multi-carousel-dot button {
  border-color: #fff !important;
}
.testimonial-section .react-multi-carousel-dot--active button {
  background: #fff !important;
}

.testimonial-slid {
  color: #fff;
}
.testimonial-slid p {
  text-align: center !important;
  font-size: 1.1rem;
}
.react-multi-carousel-list {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .testimonial-slid p {
    font-size: 1.8rem;
  }
  .testimonial-slid span {
    font-size: 1.3rem;
  }
}

.gallery-img-border-radius{
  border-radius: 22px !important
}

.imagegallery-section .slick-next, .imagegallery-section .slick-prev, .imagegallery-section .slick-dots{
  display: none !important;
}